import React from 'react';

import MossRockLandscaping from '../projects/MossRockLandscaping';
import Spades from '../projects/Spades';
import Wedding from '../projects/Wedding';

const Porfolio = () =>
    <div
        style={{
            width: '100vw'
        }}
    >
        <MossRockLandscaping />
        <Spades />
        <Wedding />
    </div>;

export default Porfolio;
