import {blue, red, yellow} from '../styles/colors';

export const MOSS_ROCK = 'mossRock';
export const WEDDING = 'wedding';
export const SPADES = 'spades';

export const projects = {
    [MOSS_ROCK]: {
        ctaIcon: require('../assets/icons/blue-arrow.png'),
        ctaText: 'SEE OUR MOSS ROCK PROJECT',
        ctaTextColor: blue,
        github: 'https://github.com/DominicSherman/moss-rock-landscaping',
        link: 'https://moss-rock-landscaping.web.app',
        subText: 'A website for landscaping company "Moss Rock Landscaping". Allows users to read more about the company, see pictures of previous projects completed by Moss Rock, and contact them to request a quote using a built in form.',
        thumbnail: require('../assets/gifs/MossRock.mp4'),
        title: 'Moss Rock Landscaping'
    },
    [SPADES]: {
        appStoreLink: 'https://itunes.apple.com/us/app/spades-score-rn/id1439916717?ls=1&mt=8',
        ctaIcon: require('../assets/icons/yellow-arrow.png'),
        ctaText: 'SEE OUR SPADES APP',
        ctaTextColor: yellow,
        github: 'https://github.com/DominicSherman/spades',
        playStoreLink: 'https://play.google.com/store/apps/details?id=com.reactnative.spades',
        subText: 'A simple automated score keeping app for the card game "Spades". Available on both the App Store and Play Store.',
        thumbnail: require('../assets/gifs/Spades.mp4'),
        title: 'Spades Scorekeeper'
    },
    [WEDDING]: {
        ctaIcon: require('../assets/icons/red-arrow.png'),
        ctaText: 'SEE THE WEDDING WEBSITE',
        ctaTextColor: red,
        github: 'https://github.com/DominicSherman/wedding-website',
        link: 'https://wedding-website-46644.web.app/',
        subText: 'A website for Dominic and Mary\'s wedding. Guests are able to see information about the wedding day and gift registries, learn more about the couple and the wedding party, RSVP, and even see pictures from the wedding day that were uploaded via the app "PikCloud".',
        thumbnail: require('../assets/gifs/Wedding.mp4'),
        title: 'Wedding Website'
    }
};

export const orderedProjects = [MOSS_ROCK, SPADES, WEDDING];
