import React, {Component} from 'react';
import Col from 'react-bootstrap/Col';

import {cardWrapper, centeredColumn} from '../styles/shared-styles';
import textStyles from '../css/TextStyles.module.css';
import {bios} from '../constants/bios';
import {MEET_THE_BROTHERS} from '../constants/routes';
import {navigateAndScroll} from '../services/navigation-service';

const styles = {
    bodyText: {
        paddingTop: 16
    },
    image: {
        paddingTop: 16,
        width: 180
    },
    textWrapper: {
        ...centeredColumn,
        height: '100%'
    },
    wrapper: {
        ...cardWrapper,
        cursor: 'pointer',
        height: '100%',
        padding: 16,
        textDecoration: 'none'
    }
};

export default class HomeCard extends Component {
    render() {
        const {type, history} = this.props;
        const {
            color,
            character,
            homeSummary,
            name
        } = bios[type];

        return (
            <Col
                lg={'3'}
                md={'5'}
                onClick={() => navigateAndScroll(history, MEET_THE_BROTHERS, type)}
                sm={'9'}
                style={{margin: 16}}
                xs={'12'}
            >
                <div
                    style={styles.wrapper}
                >
                    <div style={styles.textWrapper}>
                        <img
                            alt={''}
                            src={character}
                            style={styles.image}
                        />
                        <p className={textStyles.playfairMedium}>{name}</p>
                        <p className={textStyles.ralewaySmall}>{homeSummary}</p>
                    </div>
                    <p
                        className={textStyles.ralewaySmall}
                        style={{
                            color,
                            fontWeight: '600',
                            marginTop: 8,
                            textAlign: 'center'
                        }}
                    >
                        {`More about ${name}`.toUpperCase()}
                    </p>
                </div>
            </Col>
        );
    }
}
