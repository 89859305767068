import React, {Component} from 'react';

import {centeredColumn, centeredRow} from '../styles/shared-styles';
import textStyles from '../css/TextStyles.module.css';
import {bios} from '../constants/bios';
import {white} from '../styles/colors';

const icons = {
    width: '20vw',
    zIndex: 1
};

const styles = {
    background: {
        borderRadius: 400,
        height: '70vw',
        position: 'absolute',
        right: 20,
        width: '70vw',
        zIndex: 0
    },
    iconWrapper: {
        ...centeredRow,
        justifyContent: 'space-between',
        marginTop: 32,
        width: '90vw'
    },
    image: {
        borderRadius: 400,
        top: 0,
        width: '70vw',
        zIndex: 1
    },
    imageWrapper: {
        ...centeredRow,
        marginBottom: 16,
        position: 'relative',
        width: '90vw'
    },
    subHeader: {
        paddingTop: 16,
        whiteSpace: 'pre-wrap'
    },
    wrapper: {
        ...centeredColumn,
        backgroundColor: white,
        padding: 16,
        paddingBottom: 100
    }
};

export default class BioMobile extends Component {
    render() {
        const bio = bios[this.props.type];

        return (
            <div style={styles.wrapper}>
                <div style={styles.imageWrapper}>
                    <img
                        alt={''}
                        src={bio.imageMobile}
                        style={styles.image}
                    />
                    <div
                        style={{
                            ...styles.background,
                            backgroundColor: bio.color
                        }}
                    />
                </div>
                <div style={centeredColumn}>
                    <p className={textStyles.playfairTitle}>
                        {this.props.type}
                    </p>
                    <hr
                        style={{
                            backgroundColor: white,
                            border: 'none',
                            borderTop: `5px dashed ${bio.color}`,
                            color: white,
                            height: 1,
                            margin: 4,
                            width: '60vw'
                        }}
                    />
                    <p
                        className={textStyles.ralewayMedium}
                        style={styles.subHeader}
                    >
                        {bio.text}
                    </p>
                </div>
                <div style={styles.iconWrapper}>
                    <img
                        alt={''}
                        src={bio.icon1}
                        style={icons}
                    />
                    <img
                        alt={''}
                        src={bio.icon2}
                        style={icons}
                    />
                    <img
                        alt={''}
                        src={bio.icon3}
                        style={icons}
                    />
                </div>
            </div>
        );
    }
}
