import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {boxWrapper, centeredColumn} from '../styles/shared-styles';
import hero from '../assets/home/hero.svg';
import HomeCard from '../components/HomeCard';
import {darkGrey, white, yellow} from '../styles/colors';
import {orderedProjects} from '../constants/projects';
import HomeProject from '../components/HomeProject';
import textStyles from '../css/TextStyles.module.css';
import animations from '../css/Animations.module.css';
import {DOMINIC, GABRIEL, MICHAEL} from '../constants/bios';

const IMAGE_HEIGHT = 375;

const styles = {
    cardsRow: {
        backgroundColor: darkGrey,
        height: '100%',
        justifyContent: 'center',
        paddingBottom: '5%',
        paddingTop: '5%',
        width: '100%'
    },
    heroWrapper: {
        ...centeredColumn,
        marginBottom: '40vh',
        paddingBottom: IMAGE_HEIGHT / 2 + 32,
        position: 'relative',
        textAlign: 'center',
        width: '100vw'
    },
    image: {
        bottom: -IMAGE_HEIGHT / 2 + 16,
        height: IMAGE_HEIGHT,
        position: 'absolute',
        width: 'auto',
        zIndex: 5
    },
    smallText: {
        marginTop: 10,
        zIndex: 1
    },
    subText: {
        zIndex: 1
    },
    titleText: {
        zIndex: 1
    },
    whatWeDoWrapper: {
        ...boxWrapper,
        paddingBottom: '10%',
        width: '100vw'
    },
    wrapper: {
        ...centeredColumn,
        margin: 0,
        overflow: 'hidden',
        padding: 0,
        width: '100vw'
    }
};

export default class Home extends Component {
    render() {
        const {history} = this.props;

        return (
            <Container
                fluid
                style={styles.wrapper}
            >
                <div
                    style={{
                        backgroundColor: white,
                        height: '60vh',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: 40,
                        width: '100vw',
                        zIndex: 0
                    }}
                >
                    <img
                        alt={''}
                        className={animations.slideUpImage}
                        src={require('../assets/home/cloud-BG.jpg')}
                        style={{
                            height: 'auto',
                            width: '100%'
                        }}
                    />
                </div>
                <div style={styles.heroWrapper}>
                    <p
                        className={textStyles.playfairTitle}
                        style={styles.titleText}
                    >
                        {'Sherman Bros.'}
                    </p>
                    <p
                        className={textStyles.playfairMedium}
                        style={styles.subText}
                    >
                        {'Web & Mobile Development'}
                    </p>
                    <p
                        className={textStyles.ralewayMedium}
                        style={styles.smallText}
                    >
                        {'“Your business is unique, your website should be too."'}
                    </p>
                    <img
                        alt={''}
                        src={hero}
                        style={styles.image}
                    />
                    <div
                        className={animations.slideUpSun}
                        style={{
                            backgroundColor: yellow,
                            borderRadius: 400,
                            height: 225,
                            position: 'absolute',
                            right: window.width / 2,
                            top: 150,
                            width: 225,
                            zIndex: 0
                        }}
                    />
                </div>
                <Row style={styles.whatWeDoWrapper}>
                    <p className={textStyles.playfairTitle}>{'What We Do'}</p>
                    <Col style={{width: 550}}>
                        <Row style={{alignItems: 'center'}}>
                            <img
                                alt={''}
                                src={require('../assets/home/interfaces.svg')}
                                style={{width: '50%'}}
                            />
                            <Col style={{textAlign: 'right'}}>
                                <p className={textStyles.playfairMedium}>{'Development'}</p>
                                <p
                                    className={textStyles.ralewayMedium}
                                    style={styles.smallText}
                                >
                                    {'Dynamic Web Applications'}
                                </p>
                                <p
                                    className={textStyles.ralewayMedium}
                                    style={styles.smallText}
                                >
                                    {'Intuitive Mobile Apps'}
                                </p>
                                <p
                                    className={textStyles.ralewayMedium}
                                    style={styles.smallText}
                                >
                                    {'Full Stack Engineering'}
                                </p>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                alignItems: 'center',
                                marginTop: 16
                            }}
                        >
                            <Col>
                                <p className={textStyles.playfairMedium}>{'Design'}</p>
                                <p
                                    className={textStyles.ralewayMedium}
                                    style={styles.smallText}
                                >
                                    {'User-driven Design Process'}
                                </p>
                                <p
                                    className={textStyles.ralewayMedium}
                                    style={styles.smallText}
                                >
                                    {'Original Design Assets'}
                                </p>
                                <p
                                    className={textStyles.ralewayMedium}
                                    style={styles.smallText}
                                >
                                    {'Professional UI/UX'}
                                </p>
                            </Col>
                            <img
                                alt={''}
                                src={require('../assets/home/design-hand.svg')}
                                style={{width: '50%'}}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row style={styles.cardsRow}>
                    <HomeCard
                        history={history}
                        type={MICHAEL}
                    />
                    <HomeCard
                        history={history}
                        type={DOMINIC}
                    />
                    <HomeCard
                        history={history}
                        type={GABRIEL}
                    />
                </Row>
                <Col
                    style={{
                        padding: 0,
                        paddingBottom: 40
                    }}
                >
                    {
                        orderedProjects.map((project, index) =>
                            <HomeProject
                                history={history}
                                index={index}
                                key={index}
                                type={project}
                            />
                        )
                    }
                </Col>
            </Container>
        );
    }
}
