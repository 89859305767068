import React, {Component} from 'react';
import Nav from 'react-bootstrap/Nav';
import {Link} from 'react-router-dom';

import styles from '../css/NavBarLink.module.css';
import textStyles from '../css/TextStyles.module.css';

export default class NavBarLink extends Component {
    render() {
        const {text, path} = this.props;

        return (
            <Link
                className={styles.navLinkWrapper}
                style={{textDecoration: 'none'}}
                to={path}
            >
                <Nav.Link
                    as={'p'}
                    className={textStyles.ralewaySmall}
                    href={path}
                    style={{
                        fontWeight: '700',
                        ...window.location.pathname !== path ? {opacity: 0.6} : {}
                    }}
                >
                    {text}
                </Nav.Link>
            </Link>
        );
    }
}
