import React, {Component} from 'react';

import textStyles from '../css/TextStyles.module.css';
import {blue, darkGrey, mediumGray} from '../styles/colors';
import {centeredColumn} from '../styles/shared-styles';

const CHARACTER_HEIGHT = 180;
const GABE_WIDTH = 40;
const HEIGHT_ABOVE_FOOTER = -(CHARACTER_HEIGHT / 1.5);

const styles = {
    contactText: {
        color: darkGrey,
        fontWeight: '600',
        textDecoration: 'none'
    },
    dom: {
        height: CHARACTER_HEIGHT,
        left: '15vw',
        position: 'absolute',
        top: HEIGHT_ABOVE_FOOTER
    },
    gabe: {
        left: '47vw',
        position: 'absolute',
        top: -GABE_WIDTH,
        width: GABE_WIDTH,
        zIndex: -1
    },
    michael: {
        height: CHARACTER_HEIGHT,
        position: 'absolute',
        right: '15vw',
        top: HEIGHT_ABOVE_FOOTER
    },
    wrapper: {
        ...centeredColumn,
        backgroundColor: mediumGray,
        bottom: 0,
        left: 0,
        marginTop: 40,
        paddingBottom: 32,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 60,
        position: 'relative',
        textAlign: 'center',
        width: '100%'
    }
};

export default class Footer extends Component {
    render() {
        return (
            <div style={styles.wrapper}>
                <div>
                    <img
                        alt={''}
                        src={require('../assets/characters/Shermanos-Footer-Dominic.png')}
                        style={styles.dom}
                    />
                    <img
                        alt={''}
                        src={require('../assets/characters/Shermanos-Footer-Gabe.png')}
                        style={styles.gabe}
                    />
                    <img
                        alt={''}
                        src={require('../assets/characters/Shermanos-Footer-Michael.png')}
                        style={styles.michael}
                    />
                </div>
                <div style={{paddingBottom: 16}}>
                    <a
                        className={textStyles.ralewaySmall}
                        href={'mailto:shermanbrosdev@gmail.com'}
                        style={styles.contactText}
                    >
                        {'CONTACT: '}
                        <a
                            style={{
                                color: blue,
                                fontWeight: '400'
                            }}
                        >
                            {'shermanbrosdev@gmail.com'}
                        </a>
                    </a>
                </div>
                <div style={{width: '100%'}}>
                    <p
                        className={textStyles.playfairTiny}
                        style={{color: darkGrey}}
                    >
                        {'Copyright © 2019 Sherman Bros, Web Development. All Rights Reserved'}
                    </p>
                </div>
            </div>
        );
    }
}
