import React, {Component} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';

import animations from '../css/Animations.module.css';
import {boxWrapper, centeredColumn, centeredRow} from '../styles/shared-styles';
import textStyles from '../css/TextStyles.module.css';
import {sendEmail} from '../services/email-service';
import {black, yellow} from '../styles/colors';

const styles = {
    button: {
        backgroundColor: yellow,
        borderColor: yellow,
        width: '28%'
    },
    buttonWrapper: {
        ...centeredRow,
        paddingTop: '2%',
        width: '100%'
    },
    input: {
        margin: '1%'
    }
};

export default class ContactUs extends Component {
    constructor(props) {
        super(props);

        this.initialState = {
            email: '',
            name: '',
            phone: '',
            projectInfo: ''
        };

        this.state = this.initialState;
    }

    _handleSubmit = () => {
        sendEmail(this.state);
        this._resetState();
    };

    _setState = (field) => (event) => this.setState({[field]: event.target.value});

    _resetState = () => this.setState(this.initialState);

    render() {
        return (
            <Container
                fluid
                style={{
                    ...boxWrapper,
                    paddingBottom: 60,
                    width: '100vw'
                }}
            >
                <div
                    style={{
                        ...centeredColumn,
                        position: 'relative',
                        width: '50vw'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: yellow,
                            borderRadius: 400,
                            height: 225,
                            position: 'absolute',
                            right: window.width / 2,
                            top: 0,
                            width: 225,
                            zIndex: 0
                        }}
                    >
                        <img
                            alt={''}
                            className={animations.slideInPlane}
                            src={require('../assets/icons/sherman-plane.png')}
                            style={{
                                width: 160,
                                zIndex: 1
                            }}
                        />
                    </div>
                    <div
                        style={{
                            ...centeredColumn,
                            paddingTop: 140,
                            textAlign: 'center'
                        }}
                    >
                        <p className={textStyles.playfairTitle}>{'Contact Us'}</p>
                        <p
                            className={textStyles.ralewayMedium}
                            style={{paddingTop: 32}}
                        >
                            {'If you have a need for a web or mobile application, small or large, contact us below!'}
                        </p>
                    </div>
                </div>
                <Form style={{paddingTop: 16}}>
                    <Row>
                        <Col
                            lg={'4'}
                            style={{
                                ...centeredColumn,
                                justifyContent: 'space-evenly',
                                width: '35vw'
                            }}
                            xs={'12'}
                        >
                            <Form.Control
                                onChange={this._setState('name')}
                                placeholder={'Name'}
                                size={'sm'}
                                style={styles.input}
                                value={this.state.name}
                            />
                            <Form.Control
                                onChange={this._setState('email')}
                                placeholder={'Email Address'}
                                size={'sm'}
                                style={styles.input}
                                value={this.state.email}
                            />
                            <Form.Control
                                onChange={this._setState('phone')}
                                placeholder={'Phone'}
                                size={'sm'}
                                style={styles.input}
                                value={this.state.phone}
                            />
                        </Col>
                        <Col className={centeredColumn}>
                            <Form.Control
                                as={'textarea'}
                                onChange={this._setState('projectInfo')}
                                placeholder={'Tell us about your project!'}
                                rows={5}
                                size={'sm'}
                                style={{marginTop: '1%'}}
                                value={this.state.projectInfo}
                            />
                        </Col>
                    </Row>
                </Form>
                <Row style={styles.buttonWrapper}>
                    <Button
                        onClick={this._handleSubmit}
                        size={'lg'}
                        style={styles.button}
                        variant={'success'}
                    >
                        {'Submit'}
                    </Button>
                </Row>
                <div
                    style={{
                        ...centeredColumn,
                        paddingTop: 32,
                        textAlign: 'center',
                        width: '60%'
                    }}
                >
                    <p className={textStyles.playfairMedium}>{'Don\'t like filling out forms?'}</p>
                    <p
                        className={textStyles.ralewayMedium}
                        style={{paddingTop: 16}}
                    >
                        {'Just drop us an email at '}
                        <a
                            href={'mailto:shermanbrosdev@gmail.com'}
                            style={{
                                color: black,
                                fontWeight: '600',
                                textDecoration: 'none'
                            }}
                        >{'shermanbrosdev@gmail.com'}
                        </a>
                    </p>
                    <p
                        className={textStyles.ralewayMedium}
                        style={{paddingTop: 16}}
                    >
                        {'We\'ll get back to you just as fast.'}
                    </p>
                </div>
            </Container>
        );
    }
}
