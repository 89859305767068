import React, {Component} from 'react';
import {Route} from 'react-router-dom';

import Home from './screens/Home';
import Portfolio from './screens/Portfolio';
import MeetTheBrothers from './screens/MeetTheBrothers';
import ContactUs from './screens/ContactUs';
import {CONTACT_US, HOME, MEET_THE_BROTHERS, PORTFOLIO} from './constants/routes';

export default class Routing extends Component {
    render() {
        return (
            <div>
                <Route
                    component={Home}
                    exact
                    path={HOME}
                />
                <Route
                    component={Portfolio}
                    exact
                    path={PORTFOLIO}
                />
                <Route
                    component={MeetTheBrothers}
                    exact
                    path={MEET_THE_BROTHERS}
                />
                <Route
                    component={ContactUs}
                    exact
                    path={CONTACT_US}
                />
            </div>
        );
    }
}
