import React, {Component} from 'react';

import {DOMINIC, GABRIEL, MICHAEL} from '../constants/bios';
import Bio from '../components/Bio';

export default class MeetTheBrothers extends Component {
    render() {
        return (
            <div>
                <Bio type={MICHAEL} />
                <Bio type={DOMINIC} />
                <Bio type={GABRIEL} />
            </div>
        );
    }
}
