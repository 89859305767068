import {blue, red, yellow} from '../styles/colors';

import {MEET_THE_BROTHERS} from './routes';

export const DOMINIC = 'Dominic';
export const MICHAEL = 'Michael';
export const GABRIEL = 'Gabriel';

export const bios = {
    [DOMINIC]: {
        character: require('../assets/characters/Dominic.png'),
        color: yellow,
        homeSummary: 'Full-stack software engineer, with primary experience in JavaScript, React, React-Native, and GraphQL',
        icon1: require('../assets/icons/computer.svg'),
        icon2: require('../assets/icons/ping-pong-ball-and-racket.svg'),
        icon3: require('../assets/icons/binary-code.svg'),
        image: require('../assets/headshots/dominic-desktop.png'),
        imageMobile: require('../assets/headshots/dominic-mobile.jpg'),
        name: 'Dominic',
        text: 'A full-stack software engineer, currently specializing in web & mobile development. His preferred tools are React, React-Native, TypeScript, NodeJS, and GraphQL. He places a high priority on code quality, unit testing, acceptance testing, and implementing beautiful designs in a pixel-perfect manner. \n \nOutside of his 40 hour work week being a nerd, Dominic spends much of his time doing even nerdier activities, such as ping pong or programming even more. He also enjoys cycling competitively, playing cards with his wife, and watching Arsenal and the Portland Timbers not live up to expectations.'
    },
    [GABRIEL]: {
        character: require('../assets/characters/Gabe.png'),
        color: red,
        homeSummary: 'College student who likes programming',
        icon1: require('../assets/icons/laptop.svg'),
        icon2: require('../assets/icons/piano-keyboard-keys-silhouette.svg'),
        icon3: require('../assets/icons/soccer-ball.svg'),
        image: require('../assets/headshots/gabe-desktop.png'),
        imageMobile: require('../assets/headshots/gabe-mobile.jpg'),
        name: 'Gabriel',
        text: 'An aspiring software engineer currently learning new skillsets in college. He is proficient in Python, Java, JavaScript, & HTML and is working deliberately on mastering other tools. His experience and motivation have made him competitive in the market as he is credits above his graduating class. \n \n \nWhen Gabriel is not stressing over his course load, he can be found playing soccer, cracking jokes, and building community with those around him. Additionally, he enjoys working on more side projects with his brothers and learning new programming languages.'
    },
    [MICHAEL]: {
        character: require('../assets/characters/Michael.png'),
        color: blue,
        homeSummary: 'UI/UX Designer and Illustrator. Designs graphic user interface elements, as well as branding and original images.',
        icon1: require('../assets/icons/web-design.svg'),
        icon2: require('../assets/icons/bicycle.svg'),
        icon3: require('../assets/icons/drawing.svg'),
        image: require('../assets/headshots/michael-desktop.png'),
        imageMobile: require('../assets/headshots/michael-mobile.jpg'),
        name: 'Michael',
        text: 'A full-suite Graphic Designer and Illustrator who thrives on every aspect of brand creation from pencils and paper to pixels and picas. His experience and expertise covers a spectrum including video-editing and animation as well as branding, UI/UX, and product design.  \n \n \nWhen Michael is not at his computer, he can be found outdoors cycling, drawing at a local café, or practicing his exceptionally underwhelming golf swing.'
    }
};
