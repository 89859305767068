import React from 'react';

import {MOSS_ROCK, projects} from '../constants/projects';
import css from '../css/Projects.module.css';
import textStyles from '../css/TextStyles.module.css';
import {black, white} from '../styles/colors';
import {centeredRow} from '../styles/shared-styles';

const styles = {
    body: {
        color: white,
        paddingTop: 16
    },
    goToSiteText: {
        bottom: '5vh',
        color: black,
        fontWeight: '600',
        position: 'absolute',
        textDecoration: 'none',
        zIndex: 2
    },
    image: {
        bottom: 0,
        minHeight: '100%',
        position: 'absolute',
        width: '100%'
    },
    imageWrapper: {
        minHeight: '100vh',
        overflow: 'hidden',
        position: 'absolute',
        width: '100vw',
        zIndex: -5
    },
    previewWrapper: {
        ...centeredRow,
        position: 'relative',
        width: '100%'
    },
    textWrapper: {
        padding: '2%',
        textAlign: 'center',
        width: '70%',
        zIndex: 5
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        overflow: 'hidden'
    }
};

const MossRockLandscaping = () => (
    <div
        id={MOSS_ROCK}
        style={styles.wrapper}
    >
        <div
            style={styles.imageWrapper}
        >
            <img
                alt={''}
                src={require('../assets/portfolio/mossrock-background.jpg')}
                style={styles.image}
            />
        </div>
        <div
            style={styles.textWrapper}
        >
            <p
                className={textStyles.ralewayTitle}
                style={{color: white}}
            >
                {'MOSS ROCK LANDSCAPING'}
            </p>
            <p
                className={textStyles.ralewayLarge}
                style={{color: white}}
            >
                {'Premier Landscape Design Firm'}
            </p>
            <p
                className={textStyles.ralewayMedium}
                style={styles.body}
            >
                {projects[MOSS_ROCK].subText}
            </p>
        </div>
        <div style={styles.previewWrapper}>
            <a
                className={textStyles.ralewayMedium}
                href={projects[MOSS_ROCK].link}
                style={styles.goToSiteText}
                target={'_blank'}
            >
                {'GO TO SITE'}
            </a>
            <div className={css.whiteBackground} />
            <img
                alt={''}
                className={css.mock}
                src={require('../assets/portfolio/mossrock-mocks.png')}
            />
        </div>
    </div>
);

export default MossRockLandscaping;
