import React, {Component} from 'react';

import {boxWrapper, centeredColumn, centeredRow} from '../styles/shared-styles';
import textStyles from '../css/TextStyles.module.css';
import {bios} from '../constants/bios';

const icons = {
    position: 'absolute',
    width: '10vh',
    zIndex: 1
};

const styles = {
    icon1: {
        ...icons,
        top: '25vh'
    },
    icon2: {
        ...icons,
        top: '45vh'
    },
    icon3: {
        ...icons,
        top: '65vh'
    },
    image: {
        height: '80vh',
        position: 'absolute',
        top: 0
    },
    imageWrapper: {
        ...centeredRow,
        height: '100vh',
        marginRight: 16,
        position: 'relative',
        width: '25vw'
    },
    subHeader: {
        paddingTop: 16,
        whiteSpace: 'pre-wrap'
    },
    textWrapper: {
        ...centeredColumn,
        alignItems: 'flex-start',
        width: 500
    },
    wrapper: {
        ...boxWrapper,
        alignItems: 'flex-start',
        flexDirection: 'row',
        height: '100vh'
    }
};

export default class BioDesktop extends Component {
    render() {
        const bio = bios[this.props.type];

        return (
            <div style={styles.wrapper}>
                <div style={styles.imageWrapper}>
                    <img
                        alt={''}
                        src={bio.image}
                        style={styles.image}
                    />
                    <img
                        alt={''}
                        src={bio.icon1}
                        style={styles.icon1}
                    />
                    <img
                        alt={''}
                        src={bio.icon2}
                        style={styles.icon2}
                    />
                    <img
                        alt={''}
                        src={bio.icon3}
                        style={styles.icon3}
                    />
                </div>
                <div style={styles.textWrapper}>
                    <p className={textStyles.playfairTitle}>
                        {this.props.type}
                    </p>
                    <p
                        className={textStyles.ralewayMedium}
                        style={styles.subHeader}
                    >
                        {bio.text}
                    </p>
                </div>
            </div>
        );
    }
}
