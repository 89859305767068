import React from 'react';

import {projects, WEDDING} from '../constants/projects';
import css from '../css/Projects.module.css';
import textStyles from '../css/TextStyles.module.css';
import {centeredRow} from '../styles/shared-styles';
import {black} from '../styles/colors';

const styles = {
    background: {
        minHeight: '100%',
        minWidth: 400,
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        width: '100%'
    },
    body: {
        paddingTop: 16
    },
    goToSiteText: {
        bottom: '5vh',
        color: black,
        fontWeight: '600',
        position: 'absolute',
        textDecoration: 'none',
        zIndex: 2
    },
    imageWrapper: {
        height: '100vh',
        overflow: 'hidden',
        position: 'absolute',
        width: '100vw',
        zIndex: -5
    },
    logo: {
        width: '20%'
    },
    mock: {
        marginLeft: 50,
        marginTop: 50,
        minWidth: 275,
        width: '70%'
    },
    previewWrapper: {
        ...centeredRow,
        position: 'relative',
        width: '100%'
    },
    textWrapper: {
        padding: '2%',
        textAlign: 'center',
        width: '70%',
        zIndex: 5
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        overflow: 'hidden'
    }
};

const Wedding = () => (
    <div
        id={WEDDING}
        style={styles.wrapper}
    >
        <div style={styles.imageWrapper}>
            <img
                alt={''}
                src={require('../assets/portfolio/wedding-background.jpg')}
                style={styles.background}
            />
        </div>
        <div style={styles.textWrapper}>
            <p className={textStyles.ralewayTitle}>
                {'WEDDING WEBSITE'}
            </p>
            <p className={textStyles.ralewayLarge}>
                {'Registry and Information'}
            </p>
            <p
                className={textStyles.ralewayMedium}
                style={styles.body}
            >
                {projects[WEDDING].subText}
            </p>
        </div>
        <div style={styles.previewWrapper}>
            <a
                className={textStyles.ralewayMedium}
                href={projects[WEDDING].link}
                style={styles.goToSiteText}
                target={'_blank'}
            >
                {'GO TO SITE'}
            </a>
            <div className={css.whiteBackground} />
            <img
                alt={''}
                className={css.mock}
                src={require('../assets/portfolio/wedding-mocks.png')}
            />
        </div>
    </div>
);

export default Wedding;
