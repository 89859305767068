import React, {Component} from 'react';
import Col from 'react-bootstrap/Col';
import Media from 'react-media';
import {Row} from 'react-bootstrap';

import {centeredColumn, centeredRow} from '../styles/shared-styles';
import textStyles from '../css/TextStyles.module.css';
import {projects} from '../constants/projects';
import {PORTFOLIO} from '../constants/routes';
import {navigateAndScroll} from '../services/navigation-service';

const styles = {
    icon: {
        height: '100%',
        width: 60
    },
    imageWrapper: {
        ...centeredColumn,
        margin: '5%'
    },
    projectImage: {
        width: '25vw'
    },
    subText: {
        paddingTop: '5%'
    },
    wrapper: {
        ...centeredRow,
        cursor: 'pointer',
        margin: 0,
        padding: '5%',
        width: '100%'
    }
};

const isLeft = (matches, index) => !matches.medium && index % 2 === 1;

const isRight = (matches, index) => matches.medium || index % 2 === 0;

const shouldRotate = (matches, index) => matches.medium && index % 2 === 1;

const Image = ({project}) =>
    <Col
        sm={'3'}
        style={styles.imageWrapper}
    >
        <video
            autoPlay
            id={'video'}
            loop
            muted
            style={styles.projectImage}
        >
            <source
                src={project.thumbnail}
                type="video/mp4"
            />
        </video>
    </Col>;

const LeftImage = ({index, project}) =>
    <Media queries={{medium: {maxWidth: 575}}}>{
        (matches) =>
            isLeft(matches, index) ?
                <Image project={project} />
                :
                null
    }
    </Media>;

const RightImage = ({index, project}) =>
    <Media queries={{medium: {maxWidth: 575}}}>{
        (matches) =>
            isRight(matches, index) ?
                <Image project={project} />
                :
                null
    }
    </Media>;

const Icon = ({project, style}) =>
    <img
        alt={''}
        src={project.ctaIcon}
        style={{
            ...styles.icon,
            ...style
        }}
    />;

const LeftIcon = ({index, project}) =>
    <Media queries={{medium: {maxWidth: 575}}}>{
        (matches) =>
            isLeft(matches, index) ?
                <Icon
                    project={project}
                />
                :
                null
    }
    </Media>;

const RightIcon = ({index, project}) =>
    <Media queries={{medium: {maxWidth: 575}}}>{
        (matches) =>
            isRight(matches, index) ?
                <Icon
                    project={project}
                    style={{transform: shouldRotate(matches, index) ? 'rotate(180deg)' : 'rotate(0)'}}
                />
                :
                null
    }
    </Media>;

const CTA = ({index, project}) =>
    <Media queries={{medium: {maxWidth: 575}}}>{
        (matches) =>
            <div
                style={{
                    ...centeredRow,
                    justifyContent: isRight(matches, index) ? 'flex-start' : 'flex-end',
                    marginTop: 16
                }}
            >
                <LeftIcon
                    index={index}
                    project={project}
                />
                <body
                    className={textStyles.ralewaySmall}
                    style={{
                        color: project.ctaTextColor,
                        fontWeight: '600',
                        marginLeft: isRight(matches, index) ? 0 : 8,
                        marginRight: isLeft(matches, index) ? 0 : 8
                    }}
                >
                    {project.ctaText}
                </body>
                <RightIcon
                    index={index}
                    project={project}
                />
            </div>
    }
    </Media>;

export default class HomeProject extends Component {
    render() {
        const {history, index, type} = this.props;

        const project = projects[type];

        return (
            <Row
                onClick={() => navigateAndScroll(history, PORTFOLIO, type)}
                style={styles.wrapper}
            >
                <LeftImage
                    index={index}
                    project={project}
                />
                <Col
                    sm={'7'}
                >
                    <p className={textStyles.playfairTitle}>{project.title}</p>
                    <p
                        className={textStyles.ralewayMedium}
                        style={styles.subText}
                    >
                        {project.subText}
                    </p>
                    {/* eslint-disable-next-line react/jsx-pascal-case */}
                    <CTA
                        index={index}
                        project={project}
                    />
                </Col>
                <RightImage
                    index={index}
                    project={project}
                />
            </Row>
        );
    }
}
