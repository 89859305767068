import React, {Component} from 'react';
import Media from 'react-media';

import BioDesktop from './BioDesktop';
import BioMobile from './BioMobile';

export default class Bio extends Component {
    render() {
        const {type} = this.props;

        return (
            <div id={type}>
                <Media query={{maxWidth: 767}}>
                    {(matches) => matches ?
                        <BioMobile type={type} />
                        :
                        <BioDesktop type={type} />
                    }
                </Media>
            </div>
        );
    }
}
