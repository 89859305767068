export const navigateAndScroll = (history, route, id) => {
    history.push(route);
    setTimeout(() => {
        const element = document.getElementById(id);

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, 200);
};
