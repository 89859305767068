export const ENDPOINT = 'https://us-central1-sherman-email-sender.cloudfunctions.net/sendEmail/';

export const sendEmail = ({email, name, phone, projectInfo}) => {
    const to = 'dominic.sherman98@gmail.com,michael.sherman.create@gmail.com';
    const subject = `Project Request from ${name}`;
    const html = `<div><b>Contact Information</b><br /><a>${name}</a><br /><a>${email}</a><br /><a>${phone}</a><br /><div style="padding-top: 20px;"><b>Project Information</b><br /><a>${projectInfo}</a></div></div>`;

    const queryString = `${ENDPOINT}?to=${to}&subject=${subject}&html=${encodeURIComponent(html)}`;

    fetch(queryString);
};
